/* eslint-disable react/no-danger */
import dynamic from 'next/dynamic';
import { useEffect, useMemo } from 'react';
import { actions, GET_DETAIL_PROJECT } from '@containers/Home/slice';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfigLittlePlanetProject,
  selectConfigFirstSceneProject,
  selectGATagTrackingProject,
  selectGATrackingProject,
  selectListScene,
} from '@containers/Home/selectors';
import { wrapper } from 'store';
import { isServer } from '@utils/helper';
import Script from 'next/script';

const Home = dynamic(() => import('containers/Home'), {
  loading: () => <span>Loading</span>,
});

function ExportContainer() {
  const router = useRouter();

  const dispatch = useDispatch();
  const listScene = useSelector(selectListScene);
  const ga_tracking = useSelector(selectGATrackingProject);
  const first_scene = useSelector(selectConfigFirstSceneProject);
  const ggtag_tracking = useSelector(selectGATagTrackingProject);
  const config_little_planet = useSelector(selectConfigLittlePlanetProject);

  const detailFirstScene = useMemo(
    () => listScene.filter(item => item.id === first_scene)[0] || undefined,
    [listScene, first_scene],
  );

  const images = useMemo(() => {
    const imgs: any = [];
    imgs.push({
      url: 'https://dev-assets.panoee.com/statics/uploads/user/61132fdab3edf710103a71dd/media/asset/OmXZiGK8NfNHpuF7m4dO.png',
      width: 800,
      height: 600,
      alt: '',
    });

    return imgs;
  }, []);

  useEffect(() => {
    if (!isServer) {
      const _window = window as any;
      if (_window.offline_id)
        dispatch(
          GET_DETAIL_PROJECT({
            id: _window.offline_id,
            isPublic: true,
            isPayment: true,
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listScene && listScene.length) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      let scene = detailFirstScene
        ? { ...detailFirstScene }
        : { ...listScene[0] };

      if (router.query.scene)
        scene = {
          ...listScene.filter(item => item.id === router.query.scene)[0],
        } || {
          ...listScene[0],
        };

      if (params.scene) {
        scene = { ...listScene.find(item => item.id === params.scene) } || {
          ...listScene[0],
        };
      }
      // NOTE: add intro little planet for first scene loaded
      scene.config = { ...scene?.config } || {};
      scene.config = {
        ...scene.config,
        isLittlePlanet: config_little_planet?.enable || false,
        timeTransitionLittlePlanet:
          config_little_planet?.transition_time || 2.0,
      };
      dispatch(actions.setSceneSelected(scene));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listScene.length]);

  return (
    <>
      <NextSeo
        title={'Triển lãm đại hội Bình Phước'}
        description={
          'Triển lãm ảo  đại hội Bình Phước là nơi trưng bày các hoạt động của hội liên hiệp thanh niên tỉnh Bình Phước giai đoạn 2019 - 2024'
        }
        additionalLinkTags={[
          ...[
            {
              rel: 'shortcut icon',
              href: 'https://dev-assets.panoee.com/statics/uploads/user/61132fdab3edf710103a71dd/media/asset/7E81Ju6Cmo8l1TmD0sNJ.png',
            },
          ],
        ]}
        openGraph={{
          type: 'website',
          url: 'https://daihoibinhphuoc.panoee.vn',
          title: 'Triển lãm đại hội Bình Phước',
          description:
            'Triển lãm ảo  đại hội Bình Phước là nơi trưng bày các hoạt động của hội liên hiệp thanh niên tỉnh Bình Phước giai đoạn 2019 - 2024',
          images,
        }}
      />
      {ga_tracking && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${ga_tracking}`}
          />
          <Script
            id="gg-tag"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${ga_tracking}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      {ggtag_tracking && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${ggtag_tracking}`}
          />
          <Script
            id="gg-tag-1"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${ggtag_tracking}');
              `,
            }}
          />
        </>
      )}

      <Home isExport />
    </>
  );
}

export const getStaticProps = wrapper.getStaticProps(() => async () => {
  return { props: {} };
});

export default ExportContainer;
